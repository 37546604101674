import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { initApi } from '../../services/initApi';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { Pages } from '../../contexts/Location/LocationContext';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import userTypeHandlers from '../../contexts/User/helpers/userTypeHandlers';
import { Challenges } from '../../editor/types/Experiments';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  initApi(flowAPI);

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.PaymentPage,
        },
        flowAPI,
        enabledProviders: ['challengeDataProvider'],
      });

      try {
        flowAPI.controllerConfig.setProps({ ...initialProps });
      } catch (e) {
        console.info(e);
      }

      const slug = flowAPI.experiments.enabled(Challenges.enableSlugsInUrl)
        ? initialProps?.challengeData?.challenge?.settings?.seo?.slug
        : initialProps?.challengeData?.challenge?.id;

      if (!initialProps.participant) {
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });
        return;
      }
      const currentParticipantState =
        initialProps.participant.transitions[0].state;

      if (!userTypeHandlers.isInPaymentState(currentParticipantState)) {
        initialProps.goToPage({
          pageId: Pages.ThankYou,
          challengeId: slug,
        });
      }
    },
  };
};

export default createController;
